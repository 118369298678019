.section_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;

  .section_title {
    color: #94A3B8;
    font-size: x-large;
    font-weight: 200;
    margin-bottom: 24px;
    .section_icon {
      padding: 7px;
      background-color: var(--primary);
      border-radius: 100px;
      font-size: 20px;
      color: white;
      width: fit-content;
      height: fit-content;
    }
  }


}

.section_header2 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}
