// Import material theming functions
@use 'SASS:map';
@use '@angular/material' as mat;
@use '../../theme.scss' as *;

//Colors
$color-config: mat.get-color-config($my-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette:  map.get($color-config, 'accent');
$warn-palette:  map.get($color-config, 'warn');
$primary: mat.get-color-from-palette($primary-palette, 500);
$secondary: mat.get-color-from-palette($accent-palette, 500);
$light-secondary: mat.get-color-from-palette($accent-palette, 300);
$light-primary: mat.get-color-from-palette($primary-palette, 300);
$warn: mat.get-color-from-palette($warn-palette);
$brand-palette-50: mat.get-color-from-palette($primary-palette, 50);
$brand-palette-100: mat.get-color-from-palette($primary-palette, 100);
$brand-palette-200: mat.get-color-from-palette($primary-palette, 200);
$brand-palette-300: mat.get-color-from-palette($primary-palette, 300);
$brand-palette-400: mat.get-color-from-palette($primary-palette, 400);
$brand-palette-500: mat.get-color-from-palette($primary-palette, 500);
$brand-palette-600: mat.get-color-from-palette($primary-palette, 600);
$brand-palette-700: mat.get-color-from-palette($primary-palette, 700);
$brand-palette-800: mat.get-color-from-palette($primary-palette, 800);
$brand-palette-900: mat.get-color-from-palette($primary-palette, 900);
$brand-palette-950: mat.get-color-from-palette($primary-palette, 950);
$gold-palette-50: mat.get-color-from-palette($accent-palette, 50);
$gold-palette-100: mat.get-color-from-palette($accent-palette, 100);
$gold-palette-200: mat.get-color-from-palette($accent-palette, 200);
$gold-palette-300: mat.get-color-from-palette($accent-palette, 300);
$gold-palette-400: mat.get-color-from-palette($accent-palette, 400);
$gold-palette-500: mat.get-color-from-palette($accent-palette, 500);
$gold-palette-600: mat.get-color-from-palette($accent-palette, 600);
$gold-palette-700: mat.get-color-from-palette($accent-palette, 700);
$gold-palette-800: mat.get-color-from-palette($accent-palette, 800);
$gold-palette-900: mat.get-color-from-palette($accent-palette, 900);
$gold-palette-950: mat.get-color-from-palette($accent-palette, 950);
$slate-palette-50: mat.get-color-from-palette($warn-palette, 50);
$slate-palette-100: mat.get-color-from-palette($warn-palette, 100);
$slate-palette-200: mat.get-color-from-palette($warn-palette, 200);
$slate-palette-300: mat.get-color-from-palette($warn-palette, 300);
$slate-palette-400: mat.get-color-from-palette($warn-palette, 400);
$slate-palette-500: mat.get-color-from-palette($warn-palette, 500);
$slate-palette-600: mat.get-color-from-palette($warn-palette, 600);
$slate-palette-700: mat.get-color-from-palette($warn-palette, 700);
$slate-palette-800: mat.get-color-from-palette($warn-palette, 800);
$slate-palette-900: mat.get-color-from-palette($warn-palette, 900);
$slate-palette-950: mat.get-color-from-palette($warn-palette, 950);


//PALETTE BASICS
$intense-sand: #B24337;
$sand: #9D574F;
$pink: #CD7076;
$light-pink: #EED6D8;
$white: white;
$light-grey: rgb(228, 228, 228);
$header-title: #262626;
$secondary-text: #525252;
$light-green:#5cb85c;
$grey: #262626;
$approved: #2E9E62;
$approved-bg: #EBF9F2;
$denied: #C20A0A;
$denied-bg: #FEE7E7;
$pending: #CC8800;
$pending-bg: #FFF9EB;
$completed: #0041CC;
$completed-bg: #E5EEFF;
$body: #334054;
$error: #f64c4c;
$error-light: #f88686;
$disabled: #f3f3f1;
$dentsu-primary: #137888;
$dentsu-secondary: #8cbdc9;
$error-message: #f64c4c;

//PALETTE BASICS
$grey: #262626;
$secondary-text: #525252;
$black: rgb(20, 20, 20);

:root {
    --primary: #{$primary};
    --light-primary: #{$light-primary};
    --intense-sand: #{$intense-sand};
    --sand: #{$sand};
    --pink: #{$pink};
    --light-pink: #{$light-pink};
    --white: #{$white};
    --light-grey: #{$light-grey};
    --header-title: #{$header-title};
    --secondary-text: #{$secondary-text};
    --light-green: #{$light-green};
    --grey: #{$grey};
    --approved: #{$approved};
    --approved-bg: #{$approved-bg};
    --denied: #{$denied};
    --denied-bg: #{$denied-bg};
    --pending: #{$pending};
    --pending-bg: #{$pending-bg};
    --completed: #{$completed};
    --completed-bg: #{$completed-bg};
    --brand-palette-50: #{$brand-palette-50};
    --brand-palette-100: #{$brand-palette-100};
    --brand-palette-200: #{$brand-palette-200};
    --brand-palette-300: #{$brand-palette-300};
    --brand-palette-400: #{$brand-palette-400};
    --brand-palette-500: #{$brand-palette-500};
    --brand-palette-600: #{$brand-palette-600};
    --brand-palette-700: #{$brand-palette-700};
    --brand-palette-800: #{$brand-palette-800};
    --brand-palette-900: #{$brand-palette-900};
    --brand-palette-950: #{$brand-palette-950};
    --gold-palette-50: #{$gold-palette-50};
    --gold-palette-100: #{$gold-palette-100};
    --gold-palette-200: #{$gold-palette-200};
    --gold-palette-300: #{$gold-palette-300};
    --gold-palette-400: #{$gold-palette-400};
    --gold-palette-500: #{$gold-palette-500};
    --gold-palette-600: #{$gold-palette-600};
    --gold-palette-700: #{$gold-palette-700};
    --gold-palette-800: #{$gold-palette-800};
    --gold-palette-900: #{$gold-palette-900};
    --gold-palette-950: #{$gold-palette-950};
    --slate-palette-50: #{$slate-palette-50};
    --slate-palette-100: #{$slate-palette-100};
    --slate-palette-200: #{$slate-palette-200};
    --slate-palette-300: #{$slate-palette-300};
    --slate-palette-400: #{$slate-palette-400};
    --slate-palette-500: #{$slate-palette-500};
    --slate-palette-600: #{$slate-palette-600};
    --slate-palette-700: #{$slate-palette-700};
    --slate-palette-800: #{$slate-palette-800};
    --slate-palette-900: #{$slate-palette-900};
    --slate-palette-950: #{$slate-palette-950};
}
