@use "src/styles/0-base/_responsive.scss" as *;

.tableMaterial {
  scrollbar-color: var(--primary) white;
  scrollbar-width: thin;
  margin: 30px 0;
  border: 1px solid #e2e8f0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  th {
    font-size: 14px;
    font-weight: 600;
    background-color: #f1f5f9 !important;
    color: #1e293b;
    border-bottom: 1px solid #e2e8f0;
    line-height: 16px;
  }
  th:first-child {
    border-top-left-radius: 10px !important;
  }
  th:last-child {
    border-top-right-radius: 10px !important;
  }
  .operation {
    color: #9f8a5f;
    font-weight: 500;
  }

  tr:hover {
   
    td {
      cursor: pointer;
      background-color: var(--slate-palette-100) !important;
    }
  }
  tr:nth-child(odd) td {
    background-color: white;
  }
  tr:nth-child(even) td {
    background-color: #f9fafb;
  }
  td {
    border-bottom: 1px solid #e2e8f0;
    .delete-button {
      color: var(--denied);
      font-size: 16px;
      border: none;
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: rgb(225, 74, 74);
      }
    }
    mat-icon {
      color: var(--primary);
      font-size: large;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      margin: 0;
    }
    .op-date,
    .vat-number {
      color: #334155;
    }
    .companyName,
    .type,
    .amount {
      color: #565656;
    }
    .total {
      font-size: 14px;
      font-weight: 300;
      color: #64748b;
    }

    .gold-p {
      color: var(--gold-palette-500);
    }
    
    .status-aprobado {
      color: var(--approved);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--approved-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }

    .status-pendiente {
      color: #5c6970;
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: #f1f3f4;
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }

    .status-completado {
      color: var(--completed);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--completed-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }

    .status-firmado {
      color: var(--pending);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--pending-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }

    .status-cancelado,
    .status-denegado {
      color: var(--denied);
      font-size: 14px;
      padding: 2px 10px;
      border-radius: 150px;
      background-color: var(--denied-bg);
      text-align: center;
      width: fit-content;
      min-width: 88px;
    }

    height: 76px;
  }
}

.mdc-data-table__row:hover {
  background-color: var(--slate-palette-50);
}
.clickable {
  color: var(--secondary-text);
  margin: 0;
  cursor: pointer;
}

@media (max-width: 950px) {
  .tableMaterial {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
