/* You can add global styles to this file, and also import other style files */

@use "./0-base/base_dir" as *;
@use "./1-components/base_dir" as *;
@import "@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #FAFAFA;
}

* {
  font-family: var(--font) !important;
  a{
    text-decoration: none;
  }
}



mat-icon {
  font-family: 'Material Icons' !important;
  /*     color: $primary; */
}

/* .mat-tab-labels{
    justify-content: center !important;
} */

.mat-expansion-body{
  .mat-expansion-panel-body {
    display: flex;
    justify-content: space-around;
  }
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 8px;
}

.mat-mdc-table{
  border-radius: 10px;
  background-color: none !important;
}

body .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
body .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
    color: var(--grey) !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: var(--grey) !important;
}

.mat-mdc-tab-body-content {
  background-color: var(--white);
}



.border-search {

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: var(--primary) !important;
  }

}

@media only screen and (max-width: 950px) {
.mat-expansion-body .mat-expansion-panel-body {
  display: block;
}

.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 0.75;
  margin-left: 10px;
}

.justify-content[_ngcontent-ng-c1626503617] {
  display: block !important;
}
.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  display: flex !important;
  align-items: center !important;
  margin-right: 16px !important;
  flex-grow: 0 !important;
  flex-basis: auto !important;
}


}

.mat-mdc-paginator-container{
  background-color: #fafafa;
}

@media only screen and (max-width: 430px) {
.mat-mdc-form-field {
  max-width: 150px;
}
}

.mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay{
  background-color: transparent !important;
}

.mdc-text-field{
  background-color: transparent;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: transparent !important;
}

.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before{
  height: 0px;
}

.pointer {
  cursor: pointer;
}



