@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$font: 'Calibri', sans-serif;
$secondFont: 'Lato', sans-serif;

@mixin base-typo($size, $weight, $spacing) {
  font-weight: $weight;
  font-size: $size;
  letter-spacing: $spacing;
}

:root {
  --font: #{$font};
  --secondFont: #{$secondFont};
}