/* You can add global styles to this file, and also import other style files */
/* 
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* @import '~@angular/material/theming';
@include mat-core(); */

/* http://mcg.mbitson.com */

@use '@angular/material' as mat;
@include mat.core();

// Theme
$md-brand: (
    50 : #E7F2F3,
    100 : #D0E4E7,
    200 : #A1C9CF,
    300 : #71AEB7,
    400 : #42939F,
    500 : #137887,
    600 : #0F606C,
    700 : #0B4851,
    800 : #083036,
    900 : #062428,
    950 : #020C0D,
    A100 : #ffffff,
    A200 : #f3fff9,
    A400 : #c0ffe1,
    A700 : #a7ffd4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$md-gold: (
    50 : #F9F7F1,
    100 : #F4EFE4,
    200 : #E9DEC9,
    300 : #DDCEAD,
    400 : #D2BD92,
    500 : #C7AD77,
    600 : #9F8A5F,
    700 : #776847,
    800 : #776847,
    900 : #282318,
    950 : #14110C,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #e1f4ff,
    A700 : #c8ebff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-slate: (
    50 : #F8FAFC,
    100 : #F1F5F9,
    200 : #E2E8F0,
    300 : #CBD5E1,
    400 : #94A3B8,
    500 : #64748B,
    600 : #475569,
    700 : #334155,
    800 : #1E293B,
    900 : #0F172A,
    950 : #020617,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #e1f4ff,
    A700 : #c8ebff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$my-primary: mat.define-palette($md-brand, 500);
$my-secondary: mat.define-palette($md-gold, 500);
$my-warn: mat.define-palette($md-slate);
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-secondary,
   warn: $my-warn,
    
 )
));
@include mat.all-component-themes($my-theme);
/* @include mat.core-theme($my-theme);
@include mat.button-theme($my-theme); */

.mat-form-field-appearance-fill .mat-form-field-label.mat-focused {
    color: black;
  }

  .mdc-list-item__primary-text, .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: var(--grey) !important;
  }