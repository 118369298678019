@use "src/styles/0-base/_responsive.scss" as *;

.gold-button{
    color: white;
    width: max-content;
    height: 36px;
    padding: 8px 32px;
    border-radius: 8px;
    background-color: #C7AD77;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    &:hover{
      background-color: #D2BD92;
    }
    p{
      margin: 0;
      font-size: 12px;
    }
    &:disabled{
      opacity: 0.4;
      color: #565656;
      cursor: auto;
      background-color: #C7AD77;
    }
    
  }

  .stroked-gold-button {
    padding: 8px 32px;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid #D2BD92;
    height: 36px;
    border-radius: 8px;
    color: #D2BD92;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    flex-direction: row-reverse;
    mat-icon {
      font-size: large;
      display: flex;
      justify-content: center;
      align-items: center;
    }
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
    
    &:hover {
      background-color: #c7ad771a;
    }
  
    &:disabled {
      background: none;
      color: lightgrey;
      border: 2px solid lightgrey;
    }
  }

/*   input{
    border: 1px solid var(--slate-palette-300);
    background-color: white;
    border-radius: 8px;
    &:focus{
      box-shadow: 0px 0px 0px 2px #51C0FF;
      outline: none;
    }
  } */

  .checkbox{
    display: flex;
    p {
      font-size: 12px;
      color: #565656;
      font-weight: 400;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      margin: 0;
      height: fit-content;
      margin-right: 5px;
      filter: hue-rotate(320deg);
      transform: scale(1.3);
      margin: 6px;
      cursor: pointer;
      &:focus{
        box-shadow: none;
        outline: none;
      }
    }
  }

  .no-stroked-button {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    gap: 4px;
    color: var(--gold-palette-600);
    cursor: pointer;
    margin: 0 !important;
  }

  @media (max-width: $mobile) {
    .gold-button{
      font-size: 10px;
      padding: 8px 16px;
    }
    .stroked-gold-button{
      font-size: 10px;
      padding: 8px 16px;
    }
    .no-stroked-button{
      font-size: 12px;
      img{
        width: 
        15px;
        height: 15px;
        
      }
    }
  }